import {
  APRIL_2023_COMMUNITY_CALL_URL,
  MAY_2023_COMMUNITY_CALL_URL,
  JULY_2023_COMMUNITY_CALL_URL,
  AUG_2023_COMMUNITY_CALL_URL,
} from "../../utils/communityCallRoutes";

export const lastRecordedCommunityCallsArr = [
  {
    id: 1,
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1690540929/community-call_l9qcu4.png",
    title: "Hasura Community Call Launch Day - Aug 2023",
    register: "View Recording",
    registerLink: AUG_2023_COMMUNITY_CALL_URL,
    internalLink: true,
  },
  {
    id: 2,
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1685083056/comcall-thumbnail_1_iz1cbw.png",
    title: "Hasura Community Call - Jul 2023",
    register: "View Recording",
    registerLink: JULY_2023_COMMUNITY_CALL_URL,
    internalLink: true,
  },
  {
    id: 3,
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682665304/main-web/comcall-thumbnail_3_x0jx7k.jpg",
    title: "Hasura Community Call - May 2023",
    register: "View Recording",
    registerLink: MAY_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "May 25, 2023",
  },
  {
    id: 4,
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1680244521/main-web/comcall-thumbnail_e0fnlf.png",
    title: "Hasura Community Call - Apr 2023",
    register: "View Recording",
    registerLink: APRIL_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Mar 30, 2023",
  },
];
