export const COMMUNITY_CALL_BASE_URL = "/community/community-call/";

export const FEB_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/feb/`;

export const MARCH_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/march/`;

export const APRIL_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/april/`;

export const MAY_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/may/`;

export const JULY_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/july/`;

export const AUG_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/august/`;

export const SEP_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/september/`;

export const OCT_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/october/`;

export const DEC_2022_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2022/december/`;

export const JAN_2023_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2023/january/`;

export const FEB_2023_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2023/feb/`;

export const MARCH_2023_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2023/march/`;

export const APRIL_2023_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2023/april/`;

export const MAY_2023_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2023/may/`;

export const JULY_2023_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2023/july/`;

export const AUG_2023_COMMUNITY_CALL_URL = `${COMMUNITY_CALL_BASE_URL}2023/august/`;

export const All_COMMUNITY_CALLS = [
  COMMUNITY_CALL_BASE_URL,
  FEB_2022_COMMUNITY_CALL_URL,
  MARCH_2022_COMMUNITY_CALL_URL,
  MARCH_2022_COMMUNITY_CALL_URL,
  APRIL_2022_COMMUNITY_CALL_URL,
  MAY_2022_COMMUNITY_CALL_URL,
  JULY_2022_COMMUNITY_CALL_URL,
  AUG_2022_COMMUNITY_CALL_URL,
  SEP_2022_COMMUNITY_CALL_URL,
  OCT_2022_COMMUNITY_CALL_URL,
  DEC_2022_COMMUNITY_CALL_URL,
  JAN_2023_COMMUNITY_CALL_URL,
  FEB_2023_COMMUNITY_CALL_URL,
  MARCH_2023_COMMUNITY_CALL_URL,
  APRIL_2023_COMMUNITY_CALL_URL,
  MAY_2023_COMMUNITY_CALL_URL,
  JULY_2023_COMMUNITY_CALL_URL,
  AUG_2023_COMMUNITY_CALL_URL,
];

export const ACTIVE_REDIRECT_COMMUNITY_CALL = false;
