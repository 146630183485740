import React from "react";

import { lastRecordedCommunityCallsArr } from "../events/communityCallRecordingsState";
import IndivEvent from "../events/indivevent";
import UseHasuraFree from "../../components/learn/UseHasuraFree";
import restConnectorsEvent from "../events/images/rest-connectors-event.png";
import "../events/styles.scss";

const similiarEventsArr = [
  {
    demoType: "Webinar",
    imgUrl: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-graphQL-joins.jpg",
    title: "A Deep Dive into Data Federation with GraphQL Joins",
    register: "View Recording",
    registerLink: "/events/webinar/a-deep-dive-into-data-federation-with-graphql-joins/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl: restConnectorsEvent,
    title: "An introduction to REST connectors in Hasura",
    register: "View Recording",
    registerLink: "/events/webinar/rest-connectors/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura_neon.jpg",
    title: "Serverless Postgres Built for Developer Experience",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-hosts-neon/",
    internalLink: true,
  },
];

const MoreEvents = ({ location, isCommunityCallPage, withoutCloud }) => {
  const getThreeSimiliarEvents = () => {
    const currentActiveEventsArr = isCommunityCallPage
      ? lastRecordedCommunityCallsArr
      : similiarEventsArr;

    const currentPathLocation = location.pathname;

    // Step: 1 - Filtering events - Not showing the current active URL event

    const filteredEventsArr = currentActiveEventsArr.filter(({ registerLink }) => {
      if (currentPathLocation.startsWith(registerLink.slice(0, -1))) {
        return false;
      }

      return true;
    });

    // Step: 2 - Sorting Events based on id.

    const sortedEventsArr = filteredEventsArr.sort((a, b) => a.id - b.id);

    // Step: 3 - Check in case if currrent filtered array is having length of four.

    if (sortedEventsArr.length === 4) {
      return sortedEventsArr
        .slice(0, -1)
        .map(events => <IndivEvent key={events.id} events={events} isWithLabel />);
    }

    return sortedEventsArr.map(events => (
      <IndivEvent key={events.id} events={events} isWithLabel />
    ));
  };

  // ************************* //

  const headingText = isCommunityCallPage ? "Past recordings" : "Similar Events";

  // ************************* //

  return (
    <section className="commonSectionWrapper removePaddTop removePaddBottom">
      <div className="containerWrapper">
        <div className="upComingEventWrapper">
          <div className="eventsHeaderWrapper">
            <h2 className="articleSubTitle">{headingText}</h2>
          </div>
          <div className="indivEventsWrapper">{getThreeSimiliarEvents()}</div>
        </div>
      </div>
      {
        !withoutCloud && (
          <div className="containerWrapper hasuraTutorialWrapper">
            <UseHasuraFree />
          </div>
        )
      }
    </section>
  );
};

export default MoreEvents;
