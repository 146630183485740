import React from "react";
import Helmet from "react-helmet";

import Seo from "../seo";
import Layout from "../layout";
import { webinarVideosDetails } from "./AllState.js";
import { EventDetails } from "./eventDetails";
import MoreEvents from "./moreevents";
import "./styles.scss";

const WebinarVideos = props => {
  const webinarVideoUrl = props.pageContext.slug;
  const currentWebinarVideo = webinarVideosDetails[webinarVideoUrl];

  if (currentWebinarVideo) {
    const ogImage = { ogImage: currentWebinarVideo.metaTags.metaImg };

    return (
      <Layout location={props.location}>
        {currentWebinarVideo.removeGoogleIndex ? (
          <Helmet>
            <meta name={`robots`} content={`noindex, nofollow`} />
            <meta name={`og:image`} content={currentWebinarVideo.metaTags.metaImg} />
            <meta name={`og:title`} content={currentWebinarVideo.metaTags.title} />
            <meta name={`og:description`} content={currentWebinarVideo.metaTags.description} />
          </Helmet>
        ) : (
          <Seo
            title={currentWebinarVideo.metaTags.title}
            description={currentWebinarVideo.metaTags.description}
            meta={ogImage}
            canonicalLink={currentWebinarVideo.metaTags.canonicalUrl}
          />
        )}
        <EventDetails
          currentWebinarVideo={currentWebinarVideo}
          props={props}
          webinarVideoUrl={webinarVideoUrl}
        />
        <MoreEvents location={props.location} />
      </Layout>
    );
  }

  return null;
};

export default WebinarVideos;
