import React from "react";

const emojisObj = {
  UK: "🇬🇧",
  IN: "🇮🇳",
  SWISS: "🇨🇭",
  confetti: "🎉",
};

export const Emoji = ({ fontSize, emoji }) => {
  const emojiElement = emojisObj[emoji];

  return (
    <span className="emoji" role="img" aria-label="emoji" style={{ fontSize: fontSize }}>
      {emojiElement}
    </span>
  );
};
