import React from "react";

import { Image } from "../../globals/UIKit";
import webinarIcon from "./images/icon_webinar.svg";
import recordingIcon from "../webinarvideos/images/icon_recording.svg";
import rightArrowIcon from "./images/icon_arrow.svg";
import upcomingIcon from "./images/icon_upcoming.svg";
import communityIcon from "./images/community_green.svg";
import workshopIcon from "./images/community_red.svg";
import confIcon from "./images/community_orange.svg";
import calendar from "./images/calendar.svg";
import hasuraUserGroup from "./images/hasura-user-group.svg";
import localEventIcon from "./images/local-event.svg";

const Label = ({ isUpcomingEvent }) => (
  <div className="event-label">
    <Image src={isUpcomingEvent ? upcomingIcon : recordingIcon} alt="Recording" />
    {isUpcomingEvent ? "Upcoming" : "Recording"}
  </div>
);

const IndivEvent = ({ events, isWithLabel }) => {
  const eventIcon =
    events.demoType === "Webinar"
      ? webinarIcon
      : events.demoType === "Workshop"
      ? workshopIcon
      : events.demoType === "Demo"
      ? workshopIcon
      : events.demoType === "Conference"
      ? confIcon
      : events.demoType === "Hasura User Group"
      ? hasuraUserGroup
      : events.demoType === "Local Events"
      ? hasuraUserGroup
      : events.demoType === "Live Event"
      ? localEventIcon
      : events.demoType === "Virtual"
      ? localEventIcon
      : communityIcon;

  const textColor =
    events.demoType === "Webinar"
      ? "blue-text"
      : events.demoType === "Workshop"
      ? "red-text"
      : events.demoType === "Demo"
      ? "red-text"
      : events.demoType === "Conference"
      ? "orange-text"
      : events.demoType === "Hasura User Group"
      ? "blue-60-text"
      : events.demoType === "Local Events"
      ? "purple-60-text"
      : events.demoType === "Live Event"
      ? "purple-60-text"
      : events.demoType === "Virtual"
      ? "purple-60-text"
      : "green-text";

  return (
    <a
      href={events.registerLink}
      target={events?.internalLink ? "_self" : "_blank"}
      rel="noreferrer noopener"
    >
      <div className={"indivEventsList "}>
        <div className="indivEventIcon">
          <Image src={events.imgUrl} alt={events.title} width="338.66" height="183.08" />
          {isWithLabel && <Label isUpcomingEvent={events?.isUpcomingEvent || false} />}
        </div>
        <div className="indivEventsListContainer">
          <div className="articleDescSmall">
            <Image src={eventIcon} alt="webinar-icon" className="webinar_icon" />
            <span className={textColor}>{events.demoType}</span>
          </div>
          <div className="articleSubTitle">{events.title}</div>
          <div className="commonLink">
            {events.calendarDate && <Image className="leftIcon" src={calendar} alt="Calendar" />}
            {events?.calendarDate ? events.calendarDate : events.register}
            <Image src={rightArrowIcon} alt="right arrow" />
          </div>
        </div>
      </div>
    </a>
  );
};

export default IndivEvent;
